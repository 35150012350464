html{
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  display: flex;  
  flex-direction: column;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  flex-wrap:  wrap;
  color: #0b172a;
  font-size: 100%;
  overflow-x: hidden;
  min-width: 100vw;
  min-height: 100vh;
  min-width: 100%;
  min-height: 100%;
  text-align: left;
}


body{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100%;
  min-height: 100%;
  padding:0;
  margin: 0;
  border: 0;
  flex-wrap:  wrap;
  justify-items: center;
  justify-content: space-evenly;
  align-items: center;
  overflow-x: hidden;
  text-align: left;

}
.form-label{
  align-items: flex-start !important;
justify-content: left !important;
justify-items: left !important;
}
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100%;
  min-height: 100%;
  text-align: center;
  justify-items: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0%; 
font-family:"Open Sans",sans-serif;
font-size:16px;
font-size:1rem;
line-height:30px;
overflow-x: hidden;

}

.header{
  display: flex;
  flex-direction: row;
  align-items: right;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  position: fixed;
  top: 50px;
  
  width: 90vw;
  height:80px;
  position: fixed;
  top:0%;
  z-index: 6;
flex-wrap:  wrap;
background-color:#0b172a ;

}

.footer {
  position: relative;
  display: flex;
  flex-direction: row;
  bottom: 0;
  padding-top: 20px;
  width: 90vw;
  /* Set the fixed height of the footer here */
  background-color:#0b172a ;
  color:#F0EFEB;
}
.footer-menu{
  display: flex;
  flex-direction: column;  
  order:2;
  width:50%;
  justify-content:right !important;
  justify-items: right !important;
  align-items: flex-end;
}
.footer-contact{
  display: flex;
  flex-direction: column;
  order:1;
  width:50%;
  justify-content:left ;
  align-items: left;
  justify-items: flex-start;
}
.section_title{
  font-size:30px;
  font-size:2.6rem;
color:#E0FFFF !important;
line-height:3.6rem;
margin-bottom: 2rem;
position: absolute;
left: 50%;
top: 20%;
transform:  translateX(-50%);
z-index: 10;

}

.box{
  display: flex;
  flex-direction: row;
}
.sub_title{
  text-align: initial;
  padding: 10px 10px 10px 10px;
  color:#0b172a ;

}
.sub-title-wrapper{
  margin-top: 20px;
  max-width:100%; 
  padding:10px 10px 10px;
}
.section_subtitle{

  display:flex;
  flex-direction: column;
  width: 100%;
  align-content: center;
  background-color: transparent;
  font-weight: 700;
  font-size:24px;
  font-size: 1.5rem;
  font-family: Titillium Web,Arial,Helvetica,sans-serif;
  line-height: 2.5rem;
  color:#0b172a;


}
.section_subtitle:hover{
  color: #bc172a;
}
h1,h2,h3{
  font-family: Titillium Web,Arial,Helvetica,sans-serif;
}

.body{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 50px;
  padding-top: 30px;
  width: 100%;
  min-height: 80vh !important;
  flex-wrap:  wrap;
  overflow-x: hidden;
  background-color: #F0EFEB;


}


.main{
  -webkit-box-ordinal-group: 1;   /* OLD - iOS 6-, Safari 3.1-6 */
  -moz-box-ordinal-group: 1;      /* OLD - Firefox 19- */
  -ms-flex-order: 1;              /* TWEENER - IE 10 */
  -webkit-order: 1;               /* NEW - Chrome */
  order: 1;  
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  flex-basis: 50%;
  height: 400px !important;

  text-align: center;
  object-fit: contain;
}

.box1{
  -webkit-box-ordinal-group: 2;   /* OLD - iOS 6-, Safari 3.1-6 */
  -moz-box-ordinal-group: 2;      /* OLD - Firefox 19- */
  -ms-flex-order: 2;              /* TWEENER - IE 10 */
  -webkit-order: 2;               /* NEW - Chrome */
  order: 2;  
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  margin-bottom: 20px;
}
.box2{
  display: flex;
  flex-direction: row;
  width:100%;
  height:100%;
  justify-content:space-around;
}
.space{
  display: flex;
  content:" ";
  width:5%;
  box-sizing:content-box;


}
.box3{
  display: flex;
  flex-direction: column;
  justify-content:center;
  width:47.5% !important;
  align-items: center;
  background:#FFF;
  border:.5px inset rgba(0,0,0,0.25);
  box-shadow: 0 1px 2px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  flex-wrap: nowrap;
  object-fit: contain;
}
.box3:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.25), 0 4px 8px rgba(0,0,0,0.22);
}
h3{
  color:#0b172a;
}


p{  
  font-weight: 400;
  font-size:18px;
  font-size: 1.3rem;
  font-family: Titillium Web,Arial,Helvetica,sans-serif;
  line-height: 2.2rem;
  position: relative;
  justify-content: center;
  opacity: .9;
  padding: 10px 10px 10px 10px;
  
}

.fas .fa-arrow-alt{
  width:30px;
  height:30px;
}

img{
  -ms-interpolation-mode: bicubic !important; /* 2 */

}

.intro_parent {
  display: flex;
  position: relative;
  flex-direction: column;

  height: 50%;
  width: 90vw;
}

#navLInk{
  color: #FFF;
  font-family: Hind, Titillium Web, "Helvetica Neue", sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s;
  padding:10px;

}


#navLInk:hover:not(.active){
  color: #bc172a;
}

.about_wrapper{
  background-color: #F0EFEB;
  width: 80vw;
  
}
.about_wrapper::after{
  height: 10vh;
  width: 80vw;
  display: flex;
  content: " ";
  background-color: #F0EFEB;
}
.about_section{
  display: flex;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  color:#0b172a;
  text-align: left;
  background:#FFFFFF;
  border-radius:4px;
  box-shadow:0px 1.6px 3.6px rgba(0,0,0,0.13), 0px 0px 2.9px rgba(0,0,0,0.11); 
  transition :all 0.2s ease-in-out;
}
.main_section{
  display: flex;
  flex-direction: column;
  object-fit: contain;
  flex-wrap: wrap;
  color:#0b172a;
  text-align: left;
  background: #f2f2f2;
  border-radius: 3px;
  height: 100%;
  margin: 30px 0 0 0 ;
  padding-left: 30px  ;
}
.demo-editor.rdw-editor-main{
  width: 100% !important;
  height: 200px !important;
  background-color: #fff;
  color:#000;
}
.carousel-overlay{
  display: flex;
  position: relative;
  flex-wrap: wrap;
  background-color: #333;
  text-align: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 0;
}
.carousel-overlay::after{
  background-color: #333;
  text-align: center;
  height: 400px !important;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top:0;
  left:0;
  opacity: .6;
}

.carousel-overlay > *{
  z-index: 2;
  position: relative;
}
.carousel-item-div {
  background-color: white;
  text-align: center;
  width: 100%;
  height: 400px !important;

}
#carousel{
  overflow:visible;
  background-size: contain !important;
  background-repeat: no-repeat;
  overflow: hidden;
  height: 400px !important;
  width: 100%;

}


.logo-row{
  display: flex;
  flex-direction: row;

}
.logo{
  display: flex;
  position: absolute;
  left:5%;
  top:'10%' !important;
  height:60px;

  object-fit: contain;
}
.logo img {
  display: flex;
  width: 30%;
  height:60px;
  flex-wrap: nowrap;
  -webkit-filter: grayscale(30%) contrast(70%);
  filter: grayscale(30%) contrast(70%);

}

.logo img:hover {
  opacity: 1;
  -webkit-filter: none;
  filter: none;
}

.menu{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
   overflow-y:visible;
      padding-right: 30px;
  height: fit-content;
  position: relative;
  flex-basis: content;
  z-index: 2;
  cursor: pointer;

 }
.section_subtitle img{
  margin-top: 30px;
  margin-bottom: 10px;

  justify-content: center;
  object-fit: contain;

}
.section_subtitle img:hover{
  transform: scale(1.005) translateZ(75px) perspective(200px);

}
.title,.title2{
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  font-weight: 200;
  text-rendering: optimizeLegibility;
  letter-spacing: 1px;
  padding-bottom: 30px;
  padding-top: 30px;
  color:sienna;
}
.carousel-control-prev-icon, .carousel-control-next-icon {
  height: 10px;
  width: 10px;
  color: #fff;
  z-index: 3;
}
@media screen and (max-width: 768px) {
  body,html ,.App,.body{
    display: flex;
    flex-direction: column;
    overflow: visible;
    justify-content: center;
    justify-items:center;
    color:#0b172a;
    align-items:center;
    font-size:100%;
    padding:0;
    margin: 0;
    border: 0;
    overflow-x: hidden;

  -webkit-overflow-scrolling: touch;
  }

  .main{
    display: flex;
    flex-wrap: wrap;

  }
  .about_wrapper{
   display: flex;
   flex-direction: column;
   margin-left: 5vw;
  }
  .about_section{
    display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
    box-sizing: border-box;
  }
  .box1{
    display: flex;
    flex-direction: column;
  }
  .box2{

    display: flex;
    flex-direction: column;
  }
  .box3{

    display: flex;
    flex-direction: column;
    width:100% !important;

  }
  .title2,.title{
    color:#0b172a;
  }

 
  .customer,.section{
    width:90vw;
    justify-self: center;
  }
 
  .section{
    display: flex;
    position: relative;
    justify-content: center;
    
  }


 

  .intro_parent {
    display: flex;
    position: relative;
    flex-direction: column;

  }
  .carousel{
    display: flex;
    scroll-behavior: smooth;
    overflow-x:scroll;
  }
  .carousel-style {
    display: flex;
    height: 100% !important;
}

#carousel{
  display: flex;
  object-fit: contain;
  overflow-x:scroll;
  height: 100% !important;

}

  .logo{
    order:0;
    position: relative;
    width:90px;
    height:50px;
    top: 3%;
    left:0%;
    vertical-align: top;

  }


.title,.title2,section_subtitle{
  font-size: 1rem;
  line-height: 1.2rem;
}


.section_title{
  display: flex;
  font-size: 1.6rem;
  line-height: 2rem;
  width: 100%;
  text-align: center;
  top: 30%;

}
.section_subtitle{
  font-size: 1rem;
  line-height: 1.5rem;
}
.customer.card{
  width: 90vw;
  padding-right: 5px;
  padding-left: 5px;
}
.section_head{
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
}
#navLInk{
  color:#fff !important;
}
}